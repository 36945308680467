import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: Mainpost
 */
export function BlogMainpost() {
  console.log("BlogMainpost mounted.");

  return {
    destroy() {}
  }
}
