import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: Breadcrumbs
 */
export function Breadcrumbs() {
  console.log("Breadcrumbs mounted.");

  return {
    destroy(){}
  }
}
