/**
 * @file Route: blog
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "./index.scss";
import "layouts/main";
import { barbaRoute } from "@olmokit/core/barba/route";
import { ProjectRequest } from "components/Project/Request";
import { BlogMainpost } from "components/Blog/Mainpost";
import { BlogSinglepost } from "components/Blog/Singlepost";
import { BlogLittlepost } from "components/Blog/Littlepost";
import { Breadcrumbs } from "components/Breadcrumbs";

console.log("Route blog/index.js mounted.");

let components = [];

/**
 * Standard route hook.
 * Called on route's DOM insertion (after DOM rendering)
 */
function mounted() {

  components = components.concat([
    ProjectRequest(),
    BlogMainpost(),
    BlogSinglepost(),
    BlogLittlepost(),
    Breadcrumbs(),
  ]);

  try {

  } catch (e) {}
}

/**
 * Standard route hook.
 * Called on route's DOM deletion
 */
function destroyed() {
    components.forEach(({ destroy }) => destroy());
  }

// Standard required export
export default barbaRoute(mounted, destroyed);