import "./index.scss";
import { $all, on } from "@olmokit/dom";

/**
 * Component: BlogSinglepost
 */
export function BlogSinglepost() {
  console.log("BlogSinglepost mounted.");

  const cardImgs = $all(".BlogSinglepost: .bloglink");
  const cardBtns = $all(".BlogSinglepost:cta");
  
  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }
  if (cardBtns) {
    for(let i=0; i < cardBtns.length; i++){
      on(cardBtns[i], "click", () => {
        let dest = cardBtns[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }  

  return {
    destroy() {}
  }
}
